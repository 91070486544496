import type { TAttachments, TCrossPromotionsData, TPostLinkType, TPostType, TSupportedChannel } from '@typings';
import type { TFunction } from 'i18next';
export * from '@tiger21-llc/connect-shared/src/constants';
export { ROUTES, PUBLIC_AUTH_ROUTES } from './app/routes';

export const APP_TITLE = 'T21 Connect';

export const APP_HEADER_HEIGHT_PX = 80;

export const DEFAULT_GROUP_BACKGROUND_IMAGE_PATH = '/assets/images/default-group-background.jpeg';
export const DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH = '/assets/images/default-network-background.jpeg';
// NOTE: By default stream-chat uses this sprite image for chat reactions: https://getstream.imgix.net/images/emoji-sprite.png
export const CUSTOM_CHAT_REACTIONS_EMOJI_SPRITE_IMAGE_PATH = '/assets/images/custom-reactions-sprite.png';
export const APPLE_STORE_BUTTON_IMAGE_PATH = '/assets/images/apple-store-button.svg';
export const GOOGLE_PLAY_BUTTON_IMAGE_PATH = '/assets/images/google-play-button.svg';
export const T21_CONNECT_LOGO_IMAGE_PATH = '/assets/images/t21-connect-logo.png';
export const T21_CONNECT_LOGO_LABELED_IMAGE_PATH = '/assets/images/t21-connect-logo-labeled.png';
export const ASSET_ALLOCATION_TIGER21_BG_IMAGE_PATH = '/assets/images/asset-allocation-tiger21-bg.jpg';

export const PROFILE_AVATAR_IMAGE_QUALITY = 0.9;

export const GLOBAL_SEARCH = {
	recentSearchesLimit: 3,
	validSearchQueryLength: 3,
	limitOfSearchResultsByCategory: 3,
	limitOfSearchResults: 10,
};

export const MIN_SEARCH_LIMIT = 3;
export const MAX_CHARACTERS_TITLE_LIMIT = 30;
export const POST_TITLE_CHAR_LIMIT = 250;
export const POST_MESSAGE_CHAR_LIMIT = 5000;

export const POST_TYPES_BY_DATE = [
	{ value: 'Immediately', label: 'Immediately' },
	{ value: 'Schedule for later', label: 'Schedule for later' },
];

export const GIF_SENDING_HELPER_MARKDOWN =
	'Click **Shuffle** until you find the perfect GIF then click **Send** to send it, or click **Cancel** to cancel.';

export const AD_IMAGE_TYPES = 'jpg / webp / png';
export const VIDEOS_MIME_TYPE = 'video/mp4';
export const IMAGES_MIME_TYPE = 'image/*';
export const AD_IMAGE_MAX_SIZE = '5mb';
export const AD_IMAGE_MAX_WIDTH = '1500px';
export const ADDRESS_BOOK_ITEM_CLASS_KEY = 'address-book-item';
export const IMAGE = 'image';
export const FILE = 'file';
export const EVENT = 'event';
export const GIPHY = 'giphy';
export const VIDEO = 'video';

export const FIVE_MB_IN_BYTES = Math.pow(10, 6) * 5;
export const CROSS_PROMOTION_MIN_IMAGE_ASPECT_RATIO = 4;
export const MAX_POST_IMAGE_HEIGHT = 300;

export const IN_APP_PAGES = (t: TFunction) => [
	{
		value: 'member-guide-book',
		title: t('Member Guide Book'),
	},
	{
		value: 'partner-benefits',
		title: t('Member Benefits'),
	},
	{
		value: 'staff-and-support',
		title: t('Staff and Support'),
	},
	{
		value: 'refer-an-associate',
		title: t('Nominate A New Member'),
	},
	{
		value: 'covid-policies',
		title: t('Covid Policies'),
	},
];

export const POST_TYPES = (t: TFunction) => ({
	normal: t('Normal'),
	event: t('Event'),
	button: t('Button'),
	adImage: t('Ad image'),
	pinnedBanner: t('Pinned banner'),
	adVideo: t('Ad video'),
});

export const ALL_POST_TYPES: TPostType[] = ['Normal', 'Event', 'Button', 'Ad image', 'Pinned banner', 'Ad video'];

export const POST_LINK_TYPES = (t: TFunction) => ({
	externalURL: t('External URL'),
	event: t('Event'),
	inAppPage: t('In-app page'),
	activity: t('Activity'),
});
export const ALL_POST_LINK_TYPES = ['External URL', 'Event', 'In-app page', 'Activity'] as TPostLinkType[];

export const MINIMAL_TIME_DIFF_BETWEEN_SCHEDULE_AND_EXPIRE_DATES = 1800000;

export const MAXIMUM_ALLOWED_ATTACHMENTS_COUNT = 10;
export const STREAM_CHAT_BASE_URL = 'https://us-east.stream-io-cdn.com';
export const FEED_COMMENT_ID_DOM_ATTRIBUTE = 'data-feed-comment-id';
export const GLOBAL_SEARCH_INPUT_ID_DOM_ATTRIBUTE = 'global-search-input-id';

export const THREE_DAYS_IN_HOURS = '72';
export const DEAL_DESCRIPTION_CHARACTER_LIMIT = 2000;
export const DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT = 2000;
export const NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT = 2000;
export const NETWORK_CONNECTION_OPTIONAL_FIELD_CHARACTER_LIMIT = 200;
export const NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT = 1500;

export const dealStatusTabs = (t: (str: string) => string) => [
	{ id: 0, title: t('All'), value: null },
	{ id: 1, title: t('Open'), value: 'published' },
	{ id: 2, title: t('Closed'), value: 'closed' },
];

export const CROSS_PROMOTIONS_INITIAL_STATE: TCrossPromotionsData & { attachments?: TAttachments[] } = {
	author: '',
	network: '',
	learnCategoryId: null,
	postDateType: 'Immediately',
	scheduleDate: null,
	postType: 'Normal',
	event: null,
	linkType: 'External URL',
	link: null,
	buttonTitle: null,
	imageLocalUri: null,
	imageFile: null,
	expireDate: null,
	attachments: [],
	disableNotification: false,
	selectedActivity: undefined,
	adVideo: null,
	includeButton: false,
	isInitialFormDirty: false,
};

export const REPORT_CONTENT_OTHER_VALUE = 'Other';

export const NOTIFICATION_CHANNELS: TSupportedChannel[] = ['push/expo', 'chat/getStream', 'email'];
export const EMPTY_NOTIFICATION_CHANNELS: TSupportedChannel[] = ['chat/getStream'];

export const BOOKMARKS_TAB = 'bookmarks';

export const FLAG_BASE_URL = 'https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg';

export const TOP_COUNTRIES_SEARCH = ['US', 'GB', 'CA']; // [USA, United Kingdom, CANADA]

export const COPIED_TEXT = '#copied#';

/** Name of URL search params contains path to redirect after successful authentication (@see T21C-7556) [@DmitriyNikolenko] */
export const REDIRECT_TO_PARAM_NAME = 'redirectTo';
