import SearchResult from '../SearchResult/SearchResult';
import { transformSearchDefinition } from './ExpandableResultsBlock.utils';
import { GLOBAL_SEARCH, GLOBAL_SEARCH_DEFINITION } from '@constants';
import { useCallback, useEffect, useService, useTranslation } from '@hooks';
import { useSearchResultByDefinition } from '@ui-modules/globalSearch/hooks/useSearchResultByDefinition';
import type { TExpandableResultsBlockProps, TSearchHitEventOrMeeting, TSearchByIndexResponse } from '@typings';
import styles from './ExpandableResultsBlock.module.css';

const ExpandableResultsBlock = ({ searchQuery, definition, results }: TExpandableResultsBlockProps) => {
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');

	// we need to remove expandable flag before unload page, cause session operations are synchronous
	useEffect(() => {
		const removeSessionStorageExpandableDefinition = () => {
			window.sessionStorage.removeItem(`expandable:${definition}/${searchQuery}`);
		};
		window.addEventListener('beforeunload', removeSessionStorageExpandableDefinition);
		return () => {
			window.removeEventListener('beforeunload', removeSessionStorageExpandableDefinition);
		};
	}, []);

	// when we click "Show all" button, we set flag of definition to storage
	// thus, when we go to the page of the found entity, when we return, we will have an expanded menu
	const setExpandableStatusToStorage = (def: string) => {
		refetch();
		window.sessionStorage.setItem(`expandable:${def}/${searchQuery}`, 'true');
	};
	const getExpandableStatusFromStorage = (def: string) => {
		const isSearchDataExist = reactQuery.queryClient.getQueryData(reactQuery.queryKeys.searchByIndex(def, searchQuery));
		return isSearchDataExist && window.sessionStorage.getItem(`expandable:${def}/${searchQuery}`);
	};

	// when we concat Events and Meetings for single Calendar group, we need to count correct nbHits for both categories
	const getNbHitsForCalendar = (results: TSearchByIndexResponse[], definition: string) => {
		if (definition === 'calendar') {
			const t = results as TSearchHitEventOrMeeting[];
			const eventNbHits = t?.find((item) => item.definition === 'event')?.nbHits;
			const meetingNbHits = t?.find((item) => item.definition === 'meeting')?.nbHits;
			const calendarNbHits = eventNbHits && meetingNbHits ? eventNbHits + meetingNbHits : results?.length;
			return calendarNbHits;
		} else {
			return results[0].nbHits;
		}
	};

	// data for working with search category
	const { searchResultByIndex, refetch } = useSearchResultByDefinition(
		searchQuery,
		definition,
		getNbHitsForCalendar(results, definition),
	);

	// formatting data for first render --- do we have 3 entities or more, do we need to render full list or not, etc.
	const moreThanThreeEntities = results.length > GLOBAL_SEARCH.limitOfSearchResultsByCategory;
	const entities = moreThanThreeEntities ? results.slice(0, GLOBAL_SEARCH.limitOfSearchResultsByCategory) : results;
	const renderAllByExpandableState = getExpandableStatusFromStorage(definition) ? searchResultByIndex : entities;

	const getSeeAllButton = useCallback(
		(nbHits: number, def: string) => {
			return (
				<div
					className={styles.expandableBlock__showAllWrapper}
					onClick={() => setExpandableStatusToStorage(def)}
					onKeyPress={() => setExpandableStatusToStorage(def)}
				>
					<span>{`${t('Show all')} ${nbHits}`}</span>
					&nbsp;
					<span className={styles.expandableBlock__category}>{`${transformSearchDefinition(def, true)}`}</span>
				</div>
			);
		},
		[searchQuery],
	);

	const renderSwitch = (result: TSearchByIndexResponse, index: number) => {
		switch (result.definition) {
			case GLOBAL_SEARCH_DEFINITION.member:
				return (
					<div className={styles.test} key={result.objectID}>
						<SearchResult
							avatar={result.avatar}
							biography={result.biography}
							communities={result.communities}
							definition={result.definition}
							expertise={result.expertise}
							interests={result.interests}
							investmentExpertise={result.investmentExpertise}
							name={result.name}
							objectID={result.profileID ?? result.objectID}
							profileType={result.profileType}
							searchQuery={searchQuery}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.network:
			case GLOBAL_SEARCH_DEFINITION.group:
			case GLOBAL_SEARCH_DEFINITION.chapter:
				return (
					<div key={result.objectID}>
						<SearchResult
							definition={result.definition}
							objectID={result.objectID}
							searchQuery={searchQuery}
							title={result.title}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.event:
			case GLOBAL_SEARCH_DEFINITION.meeting: {
				const nbHitsForCalendar = getNbHitsForCalendar(results as TSearchHitEventOrMeeting[], 'calendar');
				return (
					<div key={result.objectID}>
						<SearchResult
							communityName={result.communityName}
							definition={result.definition}
							inPersonType={result.inPerson}
							objectID={result.objectID}
							searchQuery={searchQuery}
							startDate={result.startDate}
							title={result.title}
							virtualType={result.virtual}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(nbHitsForCalendar, definition)}
					</div>
				);
			}
			case GLOBAL_SEARCH_DEFINITION.discussion:
				return (
					<div key={result.objectID}>
						<SearchResult
							communityName={result.communityName}
							communitySlug={result.communitySlug}
							definition={result.definition}
							name={result.title}
							objectID={result.objectID}
							searchQuery={searchQuery}
							startDate={result.startDate}
							streamActivityId={result.streamActivityId}
							streamFeedId={result.streamFeedId}
							title={result.title}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.learn:
				return (
					<div key={result.objectID}>
						<SearchResult
							definition={result.definition}
							name={result.title}
							objectID={result.objectID}
							searchQuery={searchQuery}
							startDate={result.startDate}
							streamActivityId={result.streamActivityId}
							streamFeedId={result.streamFeedId}
							title={result.title}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.deal:
				return (
					<div key={result.objectID}>
						<SearchResult
							assetClass={result.assetClass}
							avatar={result.avatar}
							definition="deal"
							objectID={result.objectID}
							searchQuery={searchQuery}
							startDate={result.startDate}
							title={result.title}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.networkConnection:
				return (
					<div key={result.objectID}>
						<SearchResult
							avatar={result.avatar}
							communityName={result.communityName}
							definition="networkConnection"
							objectID={result.objectID}
							searchQuery={searchQuery}
							startDate={result.startDate}
							title={result.title}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
			case GLOBAL_SEARCH_DEFINITION.document:
				return (
					<div key={result.objectID}>
						<SearchResult
							definition={GLOBAL_SEARCH_DEFINITION.document}
							fileOrFolder={result.fileOrFolder}
							folderID={result.folderID}
							mimeType={result.mimeType}
							name={result.title}
							objectID={result.objectID}
							relatedEntityDefinition={result.relatedEntityDefinition}
							relatedEntityId={result.relatedEntityId}
							relatedEntityName={result.relatedEntityName}
							searchQuery={searchQuery}
							startDate={result.startDate}
						/>
						{moreThanThreeEntities &&
							index + 1 === GLOBAL_SEARCH.limitOfSearchResultsByCategory &&
							!getExpandableStatusFromStorage(definition) &&
							getSeeAllButton(result.nbHits, definition)}
					</div>
				);
		}
	};

	return (
		<div
			style={{
				marginBottom: 20,
			}}
		>
			{entities.length ? (
				<span className={styles.expandableBlock__definition}>
					{transformSearchDefinition(definition, entities?.length > 1, true)}
				</span>
			) : null}
			{renderAllByExpandableState &&
				renderAllByExpandableState.map((result, index) => {
					return result && renderSwitch(result, index);
				})}
		</div>
	);
};

export default ExpandableResultsBlock;
